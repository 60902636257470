// 导入 crypto-js 包
//const CryptoJs = require('crypto-js')
//const CryptoJS = require('crypto-js');
// import CryptoJs from 'crypto-js'
// 设置数据块长度
//const keySize = 112;

/**
 * 生成密钥字节数组, 原始密钥字符串不足112位, 补填0.
 * @param {string} key - 原始 key 值
 * @return Buffer
 */
/*const fillKey = (key) => {
    const filledKey = Buffer.alloc(keySize / 8);
    const keys = Buffer.from(key);
    if (keys.length < filledKey.length) {
        filledKey.map((b, i) => filledKey[i] = keys[i]);
    }
    return filledKey;
}*/
export function generatekey(num) {
        let library = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let key = "";
        for (var i = 0; i < num; i++) {
            let randomPoz = Math.floor(Math.random() * library.length);
            key += library.substring(randomPoz, randomPoz + 1);
        }
        return key;
    }

 /*  export function aesEncrypt(data,key){
       let datas = CryptoJS.enc.Utf8.parse(data);
       // let srcs = CryptoJS.enc.Utf8.parse(data);
       let encrypted = CryptoJS.AES.encrypt(datas, CryptoJS.MD5(key), {
           iv:[],
           mode: CryptoJS.mode.ECB,
           padding: CryptoJS.pad.Pkcs7
       });
       // return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
       var base64Cipher = CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
       return base64Cipher;*/
      /* const cipher = CryptoJS.AES.encrypt(data, key, {
           mode: CryptoJS.mode.ECB,
           padding: CryptoJS.pad.Pkcs7,
       });
        // 将加密后的数据转换成 Base64
        const base64Cipher = cipher.ciphertext.toString(CryptoJS.enc.Base64);
        // 返回加密后的经过处理的 Base64
        return base64Cipher;*/
       //const resultCipher = base64Cipher.replace(/\+/g,'-').replace(/\//g,'_');



    /*export function aesDecrypt(encrypted, key){
        // 返回的是一个解密后的对象
        let keys = CryptoJS.enc.Utf8.parse(key);
        const decipher = CryptoJS.AES.decrypt(encrypted, keys, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });
        // 将解密对象转换成 UTF8 的字符串
        const resultDecipher = CryptoJS.enc.Utf8.stringify(decipher);
        // 返回解密结果
        return resultDecipher;
    }
*/
 /*
 /**
   * 加密
   */
/*encrypt(word) {
    let key = CryptoJs.enc.Utf8.parse("1234567890ABCDEF");
    let srcs = CryptoJs.enc.Utf8.parse(word);
    let encrypted = CryptoJs.AES.encrypt(srcs, key, {
        mode: CryptoJs.mode.ECB,
        padding: CryptoJs.pad.Pkcs7
    });
    return encrypted.toString();
},

/!**
 * 解密
 *!/
decrypt(word) {
    let key = CryptoJs.enc.Utf8.parse("1234567890ABCDEF");
    let decrypt = CryptoJs.AES.decrypt(word, key, {
        mode: CryptoJs.mode.ECB,
        padding: CryptoJs.pad.Pkcs7
    });
    return CryptoJs.enc.Utf8.stringify(decrypt).toString();
}

 fillKey (key){
    const filledKey = Buffer.alloc(keySize);
    const keys = Buffer.from(key);
    if (keys.length < filledKey.length) {
        filledKey.map((b, i) => filledKey[i] = keys[i]);
    }
    return filledKey;
}*/

/**
 * 定义加密函数
 * @param {string} data - 需要加密的数据, 传过来前先进行 JSON.stringify(data);
 * @param {string} key - 加密使用的 key
 */
/*const aesEncrypt = (data, key) => {
    /!**
     * CipherOption, 加密的一些选项:
     *   mode: 加密模式, 可取值(CBC, CFB, CTR, CTRGladman, OFB, ECB), 都在 CryptoJS.mode 对象下
     *   padding: 填充方式, 可取值(Pkcs7, AnsiX923, Iso10126, Iso97971, ZeroPadding, NoPadding), 都在 CryptoJS.pad 对象下
     *   iv: 偏移量, mode === ECB 时, 不需要 iv
     * 返回的是一个加密对象
     *!/
    const cipher = CryptoJS.AES.encrypt(data, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
        iv: '',
    });
    // 将加密后的数据转换成 Base64
    const base64Cipher = cipher.ciphertext.toString(CryptoJS.enc.Base64);
    // 处理 Android 某些低版的BUG
    const resultCipher = base64Cipher.replace(/\+/g,'-').replace(/\//g,'_');
    // 返回加密后的经过处理的 Base64
    return resultCipher;
}*/

/**
 * 定义解密函数
 * @param {string} encrypted - 加密的数据;
 * @param {string} key - 加密使用的 key
 */

/*const aesDecrypt = (encrypted, key) => {
    // 先将 Base64 还原一下, 因为加密的时候做了一些字符的替换
    const restoreBase64 = encrypted.replace(/\-/g,'+').replace(/_/g,'/');
    // 这里 mode, padding, iv 一定要跟加密的时候完全一样
    // 返回的是一个解密后的对象
    const decipher = CryptoJS.AES.decrypt(restoreBase64, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
        iv: '',
    });
    // 将解密对象转换成 UTF8 的字符串
    const resultDecipher = CryptoJS.enc.Utf8.stringify(decipher);
    // 返回解密结果
    return resultDecipher;
}*/


// 获取填充后的key
//const key = CryptoJS.enc.Utf8.parse(fillKey(initKey));

/*
// 定义需要加密的数据
const data = {"password":"qwe123!@#","userName":"wing@email.com"};
// 调用加密函数
const encrypted = aesEncrypt(JSON.stringify(data), key);
// 调用解密函数
const decrypted = aesDecrypt(encrypted, key);
// 控制台输出查看结果
//console.log('加密结果: ', encrypted);
//console.log('解密结果: ', decrypted);*/
import CryptoJS from 'crypto-js'; // 先全局安装：npm install crypto-js --save-dev

//aes ecb 128 加密
export const aesEncrypt = (content, secret) => {
    const srcs = CryptoJS.enc.Utf8.parse(content);
    const encrypted = CryptoJS.AES.encrypt(srcs, CryptoJS.MD5(secret), {
        iv: [],
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}


//aes ecb 128 解密
export const aesDecrypt = (content, secret) => {
    //content为密文，无需做处理即可
    const decrypt = CryptoJS.AES.decrypt(content, CryptoJS.MD5(secret) , {
        iv: [],
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
}