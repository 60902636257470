<template>
    <div class="personalCenter">
            <el-container>
                <div v-if="isEorD == '1'" class="personalinfo">
                    <el-aside width="100%">
                        <el-form :model="accountForm" ref="accountForm" :rules="rules" label-width="100px">
<!--                          <el-row>
                            <el-col :span="12">
                              <el-form-item :label="$t('message.personcenter.account')" prop="account" >
                                <el-input v-model="accountForm.account" disabled style="width: 80%;"></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item   :label="$t('message.personcenter.username')" prop="username" required>
                                <el-input v-model="accountForm.username" style="width: 80%;"></el-input>
                              </el-form-item>
                            </el-col>
                          </el-row>-->
                          <el-row>
                            <el-col :span="12">
                              <el-form-item :label="$t('message.personcenter.mailbox')" prop="fakeemail" >
                                <el-input v-model="accountForm.fakeemail" :disabled="false"  @blur="checkemail"  style="width: 80%;"></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item :label="$t('message.personcenter.phonenumber')" prop="mobile">
                                <el-input :placeholder="$t('message.telephonegeshi')" @blur="checkphone" :disabled="accountForm.mobile"  v-model="accountForm.mobile" style="width: 80%;"></el-input>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="12">
                              <el-form-item :label="$t('message.personcenter.password')"  v-if="mouseoverpwd" @mouseover="this.mouseoverpwd=false" required>
                                <el-input disabled  v-model="accountForm.passwd" show-password style="width: 80%;"></el-input>
                                </el-form-item>
                              <el-form-item :label="$t('message.personcenter.password')"  @mouseleave="this.mouseoverpwd=true" v-if="!mouseoverpwd" required>
                              <el-button  style="width: 80%;"  @click="dialogVisible = true">{{$t('message.personcenter.changepassword')}}</el-button>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item :label="$t('message.personcenter.department')">
                                <el-input
                                    v-model="accountForm.department"
                                    class="inline-input"
                                    style="width:80%"
                                />
                              </el-form-item>
                            </el-col>
                          </el-row>

                        </el-form>
                          <el-form v-model="accountdetailForm" label-width="100px">
                            <el-row>
                              <el-col :span="12">
                                <el-form-item :label="$t('message.personcenter.sex')">
                                  <el-radio v-model="accountdetailForm.sex" :label="$t('message.personcenter.man')" size="large">{{$t('message.personcenter.man')}}</el-radio>
                                  <el-radio v-model="accountdetailForm.sex" :label="$t('message.personcenter.woman')" size="large">{{$t('message.personcenter.woman')}}</el-radio>
                                </el-form-item>
                              </el-col>
                              <el-col :span="12">
                                <el-form-item :label="$t('message.personcenter.birth')">
                                  <el-date-picker v-model="accountdetailForm.birth" type="date" value-format="yyyy-MM-dd" :placeholder="$t('message.birth_please')" />
                                </el-form-item>
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col :span="12">
                                <el-form-item :label="$t('message.personcenter.qq')">
                                  <el-input v-model="accountdetailForm.qq" style="width: 80%;"></el-input>
                                </el-form-item>
                              </el-col>
                              <el-col :span="12">
                                <el-form-item :label="$t('message.personcenter.wechat')">
                                  <el-input v-model="accountdetailForm.wechat" style="width: 80%;"></el-input>
                                </el-form-item>
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col :span="12">
                                <el-form-item :label="$t('message.personcenter.twitter')">
                                  <el-input v-model="accountdetailForm.twitter" style="width: 80%;"></el-input>
                                </el-form-item>
                              </el-col>
                              <el-col :span="12">
                                <el-form-item :label="$t('message.personcenter.skype')">
                                  <el-input v-model="accountdetailForm.skype" style="width: 80%;"></el-input>
                                </el-form-item>
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col :span="12">
                                <el-form-item :label="$t('message.personcenter.facebook')">
                                  <el-input v-model="accountdetailForm.facebook" style="width: 80%;"></el-input>
                                </el-form-item>
                              </el-col>
                              <el-col :span="12">
                                <el-form-item :label="$t('message.personcenter.whatsapp')">
                                  <el-input v-model="accountdetailForm.whatsapp" style="width: 80%;"></el-input>
                                </el-form-item>
                              </el-col>
                            </el-row>
                          </el-form>
                            <el-button type="primary" size="medium" @click="saveDetail">{{$t('message.personcenter.modifyinfor')}}</el-button>
                            <el-button type="primary" size="medium" @click="cancelsave">{{$t('message.personcenter.cancel')}}</el-button>

                    </el-aside>
                </div>
                <div v-else class="personalinfo">
                  <el-descriptions
                      class="margin-top"
                      :title="$t('message.personcenter.viewperson')"
                      :column="2"
                      :size="size"
                      border
                  >
                    <template #extra>
                      <el-button size="small" type="primary" @click="onModify">{{$t('message.modify')}}</el-button>
                    </template>
                    <el-descriptions-item>
                      <template #label>
                        <div class="cell-item">
                          {{ $t('message.personcenter.mailbox') }}
                        </div>
                      </template>
                      {{accountForm.fakeemail}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                      <template #label>
                        <div class="cell-item">
                          {{ $t('message.personcenter.phonenumber') }}
                        </div>
                      </template>
                      {{accountForm.mobile}}
                    </el-descriptions-item>
<!--                    <el-descriptions-item>
                      <template #label>
                        <div class="cell-item">
                          {{$t('message.personcenter.account')}}
                        </div>
                      </template>
                      {{accountForm.account}}
                    </el-descriptions-item>-->
                    <el-descriptions-item>
                      <template #label>
                        <div class="cell-item">
                          {{ $t('message.personcenter.password') }}
                        </div>
                      </template>
                      ****** <el-button size="mini" round @click="dialogVisible = true">{{$t('message.personcenter.changepassword')}}</el-button>
                    </el-descriptions-item>
<!--                    <el-descriptions-item>
                      <template #label>
                        <div class="cell-item">
                          {{ $t('message.personcenter.username') }}
                        </div>
                      </template>
                      {{accountForm.username}}
                    </el-descriptions-item>-->
                    <el-descriptions-item>
                      <template #label>
                        <div class="cell-item">
                          {{ $t('message.personcenter.department') }}
                        </div>
                      </template>
                      {{accountForm.department}}
                    </el-descriptions-item>

                    <el-descriptions-item>
                      <template #label>
                        <div class="cell-item">
                          {{ $t('message.personcenter.sex') }}
                        </div>
                      </template>
                      {{accountdetailForm.sex}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                      <template #label>
                        <div class="cell-item">
                          {{ $t('message.personcenter.birth') }}
                        </div>
                      </template>
                      {{accountdetailForm.birth}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                      <template #label>
                        <div class="cell-item">
                          {{ $t('message.personcenter.qq') }}
                        </div>
                      </template>
                      {{accountdetailForm.qq}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                      <template #label>
                        <div class="cell-item">
                          {{ $t('message.personcenter.wechat') }}
                        </div>
                      </template>
                      {{accountdetailForm.wechat}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                      <template #label>
                        <div class="cell-item">
                          {{ $t('message.personcenter.twitter') }}
                        </div>
                      </template>
                      {{accountdetailForm.twitter}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                      <template #label>
                        <div class="cell-item">
                          {{ $t('message.personcenter.skype') }}
                        </div>
                      </template>
                      {{accountdetailForm.skype}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                      <template #label>
                        <div class="cell-item">
                          {{ $t('message.personcenter.facebook') }}
                        </div>
                      </template>
                      {{accountdetailForm.facebook}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                      <template #label>
                        <div class="cell-item">
                          {{ $t('message.personcenter.whatsapp') }}
                        </div>
                      </template>
                      {{accountdetailForm.whatsapp}}
                    </el-descriptions-item>
                  </el-descriptions>
                </div>
                <el-dialog
                        :title="$t('message.personcenter.changepassword')"
                        v-model="dialogVisible"
                        width="40%"
                        :before-close="handleClose">
                    <el-form class=“user-account-key” ref="form" :model="form" :rules="rules" label-width="150px">
                        <el-form-item :label="$t('message.personcenter.originalpwd')" prop="old_pwd">
                            <el-input type="password" show-password clearable :placeholder="$t('message.personcenter.originalpwd')" v-model="form.old_pwd"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('message.personcenter.newpwd')" prop="newPassword">
                            <el-input type="password" show-password clearable :placeholder="$t('message.personcenter.newpwd')" v-model="form.newPassword"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('message.personcenter.confirmnewpwd')" prop="password">
                            <el-input type="password" show-password clearable :placeholder="$t('message.personcenter.confirmnewpwd')" v-model="form.password"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit(form)">{{$t('message.save')}}</el-button>
                            <el-button @click="reset()">{{$t('message.reset')}}</el-button>
                        </el-form-item>
                    </el-form>
                </el-dialog>
            </el-container>
    </div>
</template>
<script>
  import { mapState,mapGetters, mapMutations } from 'vuex'
  import {
    getAccountDetailInfo,
    getAccountInfo,
    saveAccountDetail,
    saveAccount,
    updatepass,
    validPassword
  } from "../../api/metadata";
  import {setSercet} from '../../utils/auth'
  import {getRSAPK} from "../../api/global";
  import CryptoJS from "crypto-js";
  import { aesEncrypt, generatekey, aesDecrypt} from '../../utils/cryptojs';
  import '../../utils/cryptojs';
  //import JsEncrypt from "encryptlong"
  import JSEncrypt from "jsencrypt"
  import {parseTime} from "../../utils";
  import {ElMessageBox} from "element-plus";
  export default {
    name: 'PersonalCenter',
    inject: ['reload'],
    data() {
      var regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z_]{6,18}$/; //密码
      var regex2 = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*_-])[\da-zA-Z~!@#$%^&*_-]{6,18}$/; //密码

      const validateNewPassword = (rule, value, callback) => {
        if (value === this.form.old_pwd) {
          callback(new Error(window.vm.$t('message.personcenter.newpwdnoold')))
        }else if (!regex.test(value) && !regex2.test(value)) {
          this.$message.warning(window.vm.$t('message.regexpwd'));
        } else {
          callback()
        }
      }
      const validateNewPassword2 = (rule, value, callback) => {
        if (value !== this.form.newPassword) {
          callback(new Error(window.vm.$t('message.personcenter.twicepwd')))
        } else {
          callback()
        }
      }
      const validatesenderEmail = (rule,value,callback) => {
          if (this.accountForm.fakeemail != '') {
            var reg = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/
            if (!reg.test(this.accountForm.fakeemail)) {
              callback(new Error(window.vm.$t('message.email.please_email')));
            }
            else{
              callback();
            }
          } else {
            callback();
          }

      };
      const validatemobile= (rule, value, callback) => {
        const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      //  console.log(value);
        if (!value ) {
          callback(new Error(window.vm.$t('message.personcenter.please_phone')))
        }
        else if(!reg.test(value)){
          callback(new Error(window.vm.$t('message.personcenter.please_rphone')));
        }
        else {
          callback()
        }
      }
      return {
        isEorD:'',
        code:true,
        form: {    //修改密码
          newPassword:'',
          password:'',
          old_pwd:'',
        },
        accountdetailForm:{
          customerId:'',
          sex:'',
          birth:'',
          qq:'',
          skype:'',
          wechat:'',
          twitter:'',
          facebook:'',
          whatsapp:'',
          // accountduty:'',

        },
        accountForm:{
          username:'',//用户名
          passwd:'',
          account:'',//账号名
          email:'',
          fakeemail:'',
          mobile:'',
          department:'',//公司
        },
        userForm:{      //个人中心用户详细信息
          username:'',//用户名
          passwd:'',
          sex:'',
          birth:'',
          qq:'',
          skype:'',
          wechat:'',
          twitter:'',
          facebook:'',
          whatsapp:'',
          email:'',
          fakeemail: '',
          mobile:'',
          account:'',//账号名
          // accountduty:'',
          department:'',//公司

        },
        rules: {
          old_pwd: [{ required: true, message: window.vm.$t('message.personcenter.please_oldpwd'), trigger: 'blur' }],
          newPassword: [
              { required: true, message: window.vm.$t('message.personcenter.please_newpwd'), trigger: 'blur' },
            { validator: validateNewPassword,trigger: ['blur',] }
          ],
          password: [
            { required: true, message:window.vm.$t('message.confirmnewpwd'), trigger: 'blur' },
            { validator: validateNewPassword2, trigger: ['blur',] }
          ],
          username:[{required: true, message: window.vm.$t('message.personcenter.please_username'), trigger: 'blur'}],
          fakeemail:[{validator:validatesenderEmail, trigger: ['blur','change']}],
          // mobile:[
          //   {validator: validatemobile, required: true, trigger: ['blur','change']}
          // ]
        },
        amountVal: '',
        amountVal2: '',
        mouseoverpwd:true,
        qianbaoisShow:true,
        jifenisShow:false,
        dialogVisible:false,
        disabled: false,
        //充值参数
        rechargeParams: {
          "totalAmt": '', //金额
          "paymentType": "0", //支付方式[0:微信,1:支付宝,2:余额,3:活动]
          "transType": "0" //交易类型[0:充值,1:消费]
        },
        jifenParams: {
          "totalAmt": '', //金额
          "paymentType": "0", //支付方式[0:微信,1:支付宝,2:余额,3:活动]
          "transType": "0" //交易类型[0:充值,1:消费]
        },
        text: '',
        circleUrl:
          'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
        sizeList: ['large'],
        tableData: [
          {
            name:window.vm.$t('message.personcenter.walletamount'),
            count:'50'
          },
          {
            name:window.vm.$t('message.personcenter.pointsnumber'),
            count:'100'
          },
        ],
      }
    },
    computed:mapState({
      account: (state) => state.user.name,
      ...mapGetters(['isSideBarMobileFold','lang','isMobile','companynamegroup','accountemail' ,'customerId'])
    }),
    watch:{
      '$route' (to, from) {
        this.reload();
      },
    },
    methods: {
      querySearchcompany(queryString, callback){
        this.$store.commit('metadata/querySearchCompany',queryString)
        callback(this.companynamegroup)
      },
      checkphone(){
        let formdata=new FormData();
        if(this.accountForm.mobile!==''){
          formdata.append('value',this.accountForm.mobile)
          validPassword(formdata).then((res)=>{
            if(res.error_code==400){
              this.code = false
              ElMessageBox.confirm(
                  window.vm.$t('message.isbangdingphone'),
                  {
                    cancelButtonText: window.vm.$t('message.cancel'),
                    confirmButtonText: window.vm.$t('message.OK'),
                    type: 'info',
                  }
              )
              this.accountForm.mobile=''
            }
          })
        }
      },
      checkemail(){
        let formdata=new FormData();
        if(this.accountForm.fakeemail!==''&&this.accountForm.fakeemail!=='1'){
          formdata.append('value',this.accountForm.fakeemail)
          formdata.append('email',this.accountForm.fakeemail)
          validPassword(formdata).then((res)=>{//检测是否被注册过
            if(res.error_code==400){
              this.code = false
              ElMessageBox.confirm(
                  window.vm.$t('message.isbangdingemail'),
                  {
                    cancelButtonText: window.vm.$t('message.cancel'),
                    confirmButtonText: window.vm.$t('message.OK'),
                    type: 'info',
                  }
              )
            }
          })

        }
      },
      Selectcompany(item){
     //   console.log(item);
        this.accountForm.department = item.value
        // if(this.lang == 'en'){
        //   this.accountForm.departmentId = item.id
        //   this.accountForm.departmentCname = item.cname
        //   this.accountForm.departmentEname = item.value
        // }else {
        //   this.accountForm.departmentId = item.id
        //   this.accountForm.departmentCname = item.value
        //   this.accountForm.departmentEname = item.ename
        // }
      },
      handleEdit(index, row) {
     //   console.log(index, row)
      },
      handleDelete(index, row) {
      //  console.log(index, row)
      },
      handleClick(row){
        if(row.name == window.vm.$t('message.personcenter.walletamount')){
          this.qianbaoisShow = true;
          this.jifenisShow = false
        }
        else {
          this.qianbaoisShow = false;
          this.jifenisShow = true
        }
      },
      handleClose(done) {
        this.$confirm(window.vm.$t('message.personcenter.comfirmclose'))
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      onModify(){
        this.$router.push(`/person/personalmessage/mymessage/${1}`)
      },
      onSubmit(){
        //this.$router.push(`/person/personalmessage/mymessage/${1}`)
        this.dialogVisible=false;
        updatepass(this.form).then((res)=>{
          if(res.error_code== 200){
            this.$message.success(window.vm.$t('message.password.editpass'))
            this.$store.dispatch('user/logout');
          }else if(res.error_code== 401){
            this.$message.warning(window.vm.$t('message.password.originalpass'))
          }else{
            this.$message.warning(window.vm.$t('message.password.editerror'))
          }
        })
      },
      reset(){
        this.$refs['form'].resetFields();
      },
      amountChange(val) {
        this.rechargeParams.totalAmt = val;
        if (val == '') {
          this.disabled = false
        } else {
          this.disabled = true
        }
      },
      amountChange2(val) {
        this.jifenParams.totalAmt = val;
        if (val == '') {
          this.disabled = false
        } else {
          this.disabled = true
        }
      },
      //支付方式
      paymentTypeChange(val) {
        this.rechargeParams.paymentType = val
      },
      paymentTypeChange2(val) {
        this.jifenParams.paymentType = val
      },
      //确认支付
      async surePay() {
        if (this.rechargeParams.totalAmt == '') {
          this.$message.warning(window.vm.$t('message.personcenter.please_account'));
          return;
        }
        const res = await this.$http.post('orderInfo/createOrderInfo', this.rechargeParams)
        const {
          code,
          msg,
          result
        } = res.data
        if (code === '200') {
          //支付方式跳转
          if (this.rechargeParams.paymentType == '0') {
            this.$message.success(window.vm.$t('message.personcenter.wechatpay'));
            this.wechatPay(result);
          } else if (this.rechargeParams.paymentType == '1') {
            this.$message.success(window.vm.$t('message.personcenter.alipaypay'))
            const payDiv = document.getElementById('payDiv');
            if (payDiv) {
              document.body.removeChild(payDiv);
            }
            const div = document.createElement('div');
            div.id = 'payDiv';
            div.innerHTML = result;
            document.body.appendChild(div);
            document.getElementById('payDiv').getElementsByTagName('form')[0].submit();
          } else if (this.rechargeParams.paymentType == '2') {
            this.$message.success(window.vm.$t('message.personcenter.yuepay'));
            this.$router.push({
              name: 'order'
            })
          } else {
            this.$message.success('活动支付')
          }
        } else if (code === 401) {
          this.$message.error(msg)
          this.$router.push({
            name: 'login'
          })
        } else {
          this.$message.error(msg)
        }
      },
      //微信支付
      wechatPay(result) {
        if (result) {
          const orderParams = JSON.parse(result);
          sessionStorage.qrurl = orderParams.qrurl;
          sessionStorage.amt = orderParams.amt;
          sessionStorage.returnUrl = orderParams.returnUrl;
          sessionStorage.order_id = orderParams.order_id;
          this.$router.push({
            name: 'wechatPay'
          })
        }
      },
      cancelsave(){
        this.$router.push('/person/personalmessage/mymessage')
      },
      saveDetail(){
        this.$refs.accountForm.validate((valid) => {
              if (valid) {
                //fakeemail赋值email
               if(this.code){
                 this.accountForm.email=this.accountForm.fakeemail
                 saveAccount(this.accountForm).then(response => {
                   if (response.error_code == 200) {
                     this.accountdetailForm.customerId = this.customerId
                     //  console.log(this.customerId)
                     var encryptor = new JSEncrypt() //创建非对称加密构造函数
                     getRSAPK().then(res => {
                       var pkVer = res.data.pk //获取公钥
                       encryptor.setPublicKey(pkVer)//设置公钥
                       const key = generatekey(112);//设置Key
                       var cptData = encryptor.encrypt(key) //用公钥加密 前端加密的数据
                       setSercet(cptData)
                       const gut = JSON.stringify(this.accountdetailForm)
                       var encrypted = aesEncrypt(gut, key)//调用前端AES加密方法，加密数据
                       //调用/api/accountinfo/save-secret接口，上传数据
                       saveAccountDetail(encrypted,res.data.version).then((res) => {
                         if (res.error_code == 200) {
                           this.$router.push('/person/personalmessage/mymessage')
                           this.$message.success(window.vm.$t('message.editsucc'))

                         }else{
                           this.$message.warning(window.vm.$t('message.editfail'))
                         }
                       })
                     })
                   }
                 })
               }
              }else{
                this.$alert(window.vm.$t('message.checkandrefill'), window.vm.$t('message.Incorrect'), {
                  confirmButtonText: window.vm.$t('message.OK'),
                });
              }
        })
      },
      getAccountinfo(){
        getAccountInfo().then(response=>{
          this.accountForm=response.data
          if(response.data.email!='portemail@163.com'){
            this.accountForm.fakeemail = response.data.email
            localStorage.setItem('accountemail',response.data.email)
          }
          this.accountForm.email = (this.accountemail !='')?this.accountemail:localStorage.getItem('accountemail')
        })
        getRSAPK().then(res=>{
          var pk = res.data.pk
          var version = res.data.version
          var encryptor = new JSEncrypt() //创建非对称加密构造函数
          encryptor.setPublicKey(pk)//设置公钥
          const key = generatekey(112)
          var cptData = encryptor.encrypt(key) //用公钥加密 前端加密的数据
          setSercet(cptData)
          const gut = JSON.stringify(this.accountdetailForm)
          var encrypted = aesEncrypt(gut, key)//调用前端AES加密方法，加密数据
          getAccountDetailInfo(encrypted,version).then(response=>{
            const data =JSON.parse(aesDecrypt(response.data,key))
            if(data!=null) {
              this.accountdetailForm = data
              this.accountdetailForm.birth = parseTime(data.birth,'{y}-{m}-{d}')
            }
            else{
              this.accountdetailForm= this.accountdetailForm
              //新注册的用户生日默认为当前日期
              this.accountdetailForm.birth= parseTime( new Date(),'{y}-{m}-{d}')
            }
          })
        })
      }
    },
    created(){
      this.isEorD = this.$route.params.eord
      this.getAccountinfo()

      //if (this.isEorD ==1 || this.isEorD == 2){
      //this.init_userinfo()
      //}
    }
  }
</script>
<style lang="scss">
    .personalCenter{
        width: 100%;
        height: 100%;
        .el-container{
            width: 100%;
            height: 100%;
            .personalinfo{
                width:60%;
                height: 85%;
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
              .el-input__inner{
                height: 35px;
              }
              .el-date-editor.el-input, .el-date-editor.el-input__inner{
                width: 245px;
              }
            }
            .left_container{
                width: 70%;
            }
            .right_container{
                width:100% ;
                height: 100%;
                .msg-box > li {
                    list-style: none;
                    border-bottom: 1px solid #c5c5c5;
                    padding: 20px 10px;
                }
            }
        }
        .el-aside {
            height: 100%;
            line-height: 44px;
            text-align: center;
            //background-color: #d3dce6;
        }
        .el-main {
            height: 100%;
            line-height: 36px;
            text-align: center;
            //background-color: #e9eef3;
        }

        .demo-basic--circle {
            align: 'center';
            margin-top: 30px;
            margin-left: 150px;
        }
        .block {
            width: 100%;
            margin-left: 25px;
            font-weight: bold;
        }
        .text {
            font-size: 14px;
        }
        .item {
            margin-bottom: 18px;
        }
        .clearfix:before,
        .clearfix:after {
            display: table;
            content: '';
        }
        .clearfix:after {
            clear: both;
        }

        .box-card {
            margin-bottom: 15px;
            width: 100%;
            border-radius: 20px;
        }
      .el-form-item__error {
        color: #F56C6C;
        font-size: 12px;
        line-height: 1;
        padding-top: 0px;
        position: absolute;
        top: 100%;
        left: -230px;
        width: 200%;
      }
    }

</style>

